import http from "./https";
import { Answer } from "types/survey";

export const getSurveyListAsync = (code: string) => http.get(`sm_survey/my?code=${code}`);

export const getSurveyAsync = (id: string) => http.get(`sm_survey/${id}`);

export const getEntityById = (id: number) => {
    if (http.isAPIGO){
        return http.getGO(`entity/GetForRespondApp?id=${id}`)
    }else{
        return http.get(`entity/GetOneById?id=${id}`)
    }
}

export const getSurveysByGuid = (guid: string) => {
    if (http.isAPIGO) {
        return http.getGO(`/sm/sm_survey/GetSurveyByGuid?guid=` + guid);
    } else {
        return http.get(`sm_survey/GetSurveyByGuid?guid=` + guid);
    }
}

export const getSurveysByUniqueId = (UniqueId: string, entity_id: number) => {
    if (http.isAPIGO) {
        return http.getGO(`/sm/sm_survey/GetSurveyByUniqueIdAndEntityId?UniqueId=${encodeURIComponent(UniqueId)}&entity_id=${entity_id}`);
    } else {
        return http.get(`sm_survey/GetSurveyByUniqueIdAndEntityId?UniqueId=${UniqueId}&entity_id=${entity_id}`);
    }
}

export const validateEmployeeByUniqueIdAndEntityId = (entity_id, UniqueId: string) => http.getGO(`employee/employees/ValidateEmployeeByUniqueIdAndIdEntity?entity_id=`+ entity_id+`&unique_id=` + encodeURIComponent(UniqueId));

export const getSurveyAsyncByGuid = (guid: string) => {
    if (http.isAPIGO) {
        return http.getGO(`/sm/sm_survey/GetOneByGuid?guid=` + guid);
    }else{
        return http.get(`sm_survey/GetOneByGuid?guid=` + guid)
    }
}

export const AnswerRecipient = (data: Answer) => {
    // if (http.isAPIGO) {
    //     return http.postGO(`/sm/sm_survey/AnswerSubmitRecipient`, data);
    // } else {
    // }
    return http.post(`sm_survey/AnswerSubmitRecipient`, data);

}

export const SaveAnswersTemporarily = (data: Answer) => {
    // if (http.isAPIGO) {
    //     return http.postGO(`/sm/sm_survey/SaveAnswersTemporarily`, data);
    // } else {
    // }
    return http.post(`sm_survey/SaveAnswersTemporarily`, data);

}

export const LoadEntityData = (id: string) => {
    if(http.isAPIGO){
        return http.getGO(`/entity/GetForRespondApp?id=${id}`);
    }else{
        return http.get(`entity/GetOneById?id=${id}`);
    }
} 
